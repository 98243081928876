import React, { useState, useCallback, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { FaSearch, FaDownload } from 'react-icons/fa';
import MapView from './MapView';
import { ClipLoader } from 'react-spinners';

const LocalBusinessFinder = () => {
  const [googleMapsUrl, setGoogleMapsUrl] = useState('');
  const [businessDetails, setBusinessDetails] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pointDistance, setPointDistance] = useState(3);
  const [notification, setNotification] = useState(null);
  const [loadingBusinessDetails, setLoadingBusinessDetails] = useState(false);

  const showNotification = useCallback((message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 5000);
  }, []);

  const handleFetchBusinessDetails = useCallback(async () => {
    if (!googleMapsUrl) {
      showNotification('Please enter a Google Maps URL.', 'error');
      return;
    }
  
    setLoadingBusinessDetails(true);
    setBusinessDetails(null);
  
    try {
      const response = await fetch('/api/places/fetch-details', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: googleMapsUrl }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch business details');
      }
  
      const data = await response.json();

      // Log the full API response
      console.log('Full API Response:', data);

      // Ensure you're setting the full data without filtering out any fields
      setBusinessDetails(data.data); // Or setBusinessDetails(data) if that's the correct structure

      // Log the businessDetails state
      console.log('Updated businessDetails state:', data.data);

      showNotification('Business details fetched successfully!');
    } catch (err) {
      console.error('Fetch error:', err);
      showNotification(`Error: ${err.message}`, 'error');
    } finally {
      setLoadingBusinessDetails(false);
    }
  }, [googleMapsUrl, showNotification]);

  const handleGridSearch = useCallback(async () => {
    if (!businessDetails || !keyword) {
      showNotification('Please fetch business details and enter a keyword before searching.', 'error');
      return;
    }

    setLoading(true);
    setResults([]);

    try {
      const response = await fetch('/api/places/search', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          keyword,
          targetBusinessName: businessDetails.businessName,
          centerLat: businessDetails.latitude,
          centerLng: businessDetails.longitude,
          pointDistance,
          city: businessDetails.city
        })
      });

      if (!response.ok) {
        throw new Error('Failed to perform grid search');
      }

      const data = await response.json();
      setResults(data.data);
      showNotification('Grid search completed successfully!');
    } catch (err) {
      console.error('Search error:', err);
      showNotification(`Error: ${err.message}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [businessDetails, keyword, pointDistance, showNotification]);

  const averageResults = useMemo(() => {
    if (results.length === 0) return [];

    const businessRankings = results.reduce((acc, point) => {
      point.results.forEach((business, index) => {
        const name = business.title || business.name;
        if (!acc[name]) {
          acc[name] = { totalRank: 0, count: 0 };
        }
        acc[name].totalRank += index + 1;
        acc[name].count += 1;
      });
      return acc;
    }, {});

    return Object.entries(businessRankings)
      .map(([name, data]) => ({
        name,
        averageRank: data.totalRank / data.count
      }))
      .sort((a, b) => a.averageRank - b.averageRank)
      .slice(0, 20);
  }, [results]);

  const csvData = useMemo(() => {
    return results.flatMap((point, index) => 
      point.results.map(business => ({
        'Grid Point': index + 1,
        'Grid Latitude': point.lat,
        'Grid Longitude': point.lng,
        'Business Name': business.title || business.name,
        'Business Address': business.address,
        'Business Phone': business.phone,
        'Business Website': business.website,
        'Business Rating': business.rating,
        'Keyword': keyword,
        'Target Business': businessDetails?.businessName || '',
        'Target Business Ranking': point.ranking || 'N/A'
      }))
    );
  }, [results, keyword, businessDetails]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-gray-100">
      <Notification notification={notification} />
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <SearchPanel
          googleMapsUrl={googleMapsUrl}
          setGoogleMapsUrl={setGoogleMapsUrl}
          handleFetchBusinessDetails={handleFetchBusinessDetails}
          businessDetails={businessDetails}
          keyword={keyword}
          setKeyword={setKeyword}
          pointDistance={pointDistance}
          setPointDistance={setPointDistance}
          handleGridSearch={handleGridSearch}
          loading={loading}
          loadingBusinessDetails={loadingBusinessDetails}
        />

        {results.length > 0 && (
          <SearchResults 
            results={results} 
            averageResults={averageResults} 
            csvData={csvData} 
            businessDetails={businessDetails}
            keyword={keyword}
          />
        )}
      </main>
    </div>
  );
};

const Notification = ({ notification }) => (
  notification && (
    <div className={`fixed top-4 right-4 px-4 py-2 rounded-md text-white shadow-lg ${notification.type === 'error' ? 'bg-red-500' : 'bg-green-500'}`}>
      {notification.message}
    </div>
  )
);

const Header = () => (
  <header className="bg-white shadow-sm">
    <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 flex items-center justify-between">
      <h1 className="text-2xl font-bold text-gray-800">Local Empire Grid Search</h1>
      {/* Remove the API Key Instructions link */}
      {/* <a
        href="/api-instructions"
        className="text-blue-600 hover:text-blue-800 font-medium"
      >
        API Key Instructions
      </a> */}
    </div>
  </header>
);

const SearchPanel = ({
  googleMapsUrl,
  setGoogleMapsUrl,
  handleFetchBusinessDetails,
  businessDetails,
  keyword,
  setKeyword,
  pointDistance,
  setPointDistance,
  handleGridSearch,
  loading,
  loadingBusinessDetails
}) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-8">
    <h2 className="text-xl font-semibold text-gray-800 mb-4">Fetch Business Details</h2>
    <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
      <input
        type="text"
        className="flex-grow p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-900 mb-4 md:mb-0"
        placeholder="Enter Google Maps URL"
        value={googleMapsUrl}
        onChange={(e) => setGoogleMapsUrl(e.target.value)}
      />
      <button 
        className="p-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center justify-center"
        onClick={handleFetchBusinessDetails}
        disabled={loadingBusinessDetails}
      >
        {loadingBusinessDetails ? (
          <>
            <ClipLoader size={20} color={"#fff"} loading={loadingBusinessDetails} className="mr-2" />
            Fetching...
          </>
        ) : (
          <>
            <FaSearch className="mr-2" />
            Fetch Business Details
          </>
        )}
      </button>
    </div>

    {businessDetails && (
      <>
        <BusinessDetails businessDetails={businessDetails} />
        <h2 className="text-xl font-semibold text-gray-800 mt-8 mb-4">Perform Grid Search</h2>
        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
          <input
            type="text"
            className="flex-grow p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-900 mb-4 md:mb-0"
            placeholder="Enter keyword for search"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <input
            type="number"
            className="w-32 p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-900 mb-4 md:mb-0"
            placeholder="Distance (mi)"
            value={pointDistance}
            onChange={(e) => setPointDistance(Math.max(1, parseInt(e.target.value) || 1))}
            min="1"
          />
          <button 
            className="p-3 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center justify-center"
            onClick={handleGridSearch}
            disabled={loading}
          >
            {loading ? (
              <>
                <ClipLoader size={20} color={"#fff"} loading={loading} className="mr-2" />
                Searching...
              </>
            ) : (
              <>
                <FaSearch className="mr-2" />
                Perform Grid Search
              </>
            )}
          </button>
        </div>
      </>
    )}
  </div>
);

const BusinessDetails = ({ businessDetails }) => (
  <div className="mt-6 p-6 bg-gray-50 rounded-lg shadow-inner">
    <h3 className="text-lg font-semibold mb-4 text-gray-800">Business Details</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-gray-700">
      <div>
        <DetailItem label="Name" value={businessDetails.businessName || businessDetails.name} />
        <DetailItem label="Address" value={businessDetails.address || businessDetails.full_address} />
        <DetailItem label="Phone" value={businessDetails.phoneNumber || businessDetails.phone_number} />
        <DetailItem label="Website" value={
          <a href={businessDetails.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
            {businessDetails.website}
          </a>
        } />
        {businessDetails.emails_and_contacts?.emails && businessDetails.emails_and_contacts.emails.length > 0 && (
          <DetailItem label="Email" value={businessDetails.emails_and_contacts.emails[0]} />
        )}
      </div>
      <div>
        <DetailItem label="Rating" value={`${businessDetails.rating} (${businessDetails.reviewCount} reviews)`} />
        <DetailItem label="Latitude" value={businessDetails.latitude} />
        <DetailItem label="Longitude" value={businessDetails.longitude} />
        <DetailItem label="Business ID" value={businessDetails.businessId} />
        <DetailItem label="Type" value={businessDetails.type} />
      </div>
    </div>
    {businessDetails.subtypes && businessDetails.subtypes.length > 0 && (
      <div className="mt-6">
        <h4 className="font-semibold text-gray-800">Subtypes</h4>
        <ul className="list-disc list-inside pl-4 text-gray-700">
          {businessDetails.subtypes.map((subtype, index) => (
            <li key={index}>{subtype}</li>
          ))}
        </ul>
      </div>
    )}
    {businessDetails.emails_and_contacts && (
      <div className="mt-6">
        <h4 className="font-semibold text-gray-800">Social Media</h4>
        <ul className="list-disc list-inside pl-4 text-blue-600">
          {businessDetails.emails_and_contacts.facebook && (
            <li>
              <a
                href={businessDetails.emails_and_contacts.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                Facebook
              </a>
            </li>
          )}
          {businessDetails.emails_and_contacts.instagram && (
            <li>
              <a
                href={businessDetails.emails_and_contacts.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                Instagram
              </a>
            </li>
          )}
          {businessDetails.emails_and_contacts.tiktok && (
            <li>
              <a
                href={businessDetails.emails_and_contacts.tiktok}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                TikTok
              </a>
            </li>
          )}
          {/* Add other social media platforms as needed */}
        </ul>
      </div>
    )}
  </div>
);

const DetailItem = ({ label, value }) => (
  <p className="mb-2">
    <span className="font-semibold">{label}:</span> {value || 'N/A'}
  </p>
);

const SearchResults = ({ results, averageResults, csvData, businessDetails, keyword }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4 md:mb-0">Grid Search Results</h2>
      <CSVLink
        data={csvData}
        filename={"grid_search_results.csv"}
        className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center text-sm"
      >
        <FaDownload className="mr-2" />
        Export CSV
      </CSVLink>
    </div>

    <div className="mb-8">
      <h3 className="text-lg font-semibold mb-4 text-gray-800">Map View</h3>
      <MapView 
        results={results} 
        targetBusinessName={businessDetails?.businessName}
        businessLocation={{
          latitude: businessDetails?.latitude,
          longitude: businessDetails?.longitude
        }}
        city={businessDetails?.city}
        pointDistance={businessDetails?.pointDistance}
        searchKeyword={keyword}
      />
    </div>

    <div className="mb-8">
      <h3 className="text-lg font-semibold mb-4 text-gray-800">Average Results</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Rank</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Business Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Average Ranking</th>
            </tr>
          </thead>
          <tbody className="text-gray-800">
            {averageResults.map((business, index) => (
              <tr key={business.name} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="px-6 py-3 whitespace-nowrap">{index + 1}</td>
                <td className={`px-6 py-3 whitespace-nowrap ${business.name === businessDetails?.businessName ? 'font-semibold text-green-600' : ''}`}>
                  {business.name}
                </td>
                <td className="px-6 py-3 whitespace-nowrap">{business.averageRank.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default LocalBusinessFinder;

